<template>
  <div class="d-flex flex-column h-100 flex-root">
    <div
      class="
        login
        bg-size-100
        login-1 login-signin-on
        d-flex
        flex-column flex-lg-row flex-row-fluid
        bg-white
      "
      id="kt_login"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!--begin::Aside-->
      <div
        ref="box2"
        :style="left ? 'margin-left-50%' : ''"
        class="
          login-aside
          box2
          d-flex
          flex-row-auto
          bgi-size-cover bgi-no-repeat
          p-10 p-lg-15
        "
      >
        <!-- :style="{ backgroundImage: `url(${backgroundImage})` }" -->
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <div
            class="flex-column-fluid d-flex flex-column justify-content-center"
          >
            <div
              class="
                main__logo
                animate__animated animate__fadeInUp animate__delay-.5s
              "
            >
              <img src="@/assets/bts_logo.png" alt="" />
            </div>
            <h3
              class="
                font-size-h1
                mt-10
                mb-5
                text-white
                animate__animated animate__fadeInUp animate__delay-.8s
              "
            >
              BTS EXPRESS CARGO SERVIS!
            </h3>

            <p
              class="
                font-weight-lighter
                text-white
                opacity-80
                animate__animated animate__fadeInUp animate__delay-.9s
              "
            >
              Курьерская служба ООО «BTS Express Cargo Servise»
            </p>
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div
            class="
              animate__animated animate__fadeInUp animate__delay-.9s
              d-none
              flex-column-auto
              d-lg-flex
              justify-content-between
              mt-15
            "
          >
            <div class="opacity-70 font-weight-bold text-white">
              © {{ year }} Deepcode
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
        ref="box"
        style="transform: translate(100%, 0px)"
        class="box bg-white overflow-hidden"
      >
        <div class="auth__form">
          <router-view v-on:isLogged="logged"></router-view>
        </div>
        <div class="custom_bg"></div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
// @import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { TimelineLite, Back } from 'gsap'

import { mapState } from 'vuex'
const fullyear = new Date().getFullYear()
export default {
  // components: {
  //   particlesJS
  // },
  data() {
    return {
      left: false
    }
  },

  name: 'auth',
  methods: {
    logged() {
      const { box } = this.$refs
      const timeline = new TimelineLite()

      timeline.to(box, 2, {
        x: 0,
        width: '100%',
        ease: Back.easeInOut // Specify an ease
      })
      timeline.to(
        box,
        0.5,
        {},
        '-=0.5' // Run the animation 0.5s early
      )
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    }),
    backgroundImage() {
      return require('@/assets/images/bg-123.jpg')
    },
    year() {
      return fullyear
    }
  },
  mounted() {
    const { box } = this.$refs
    const timeline = new TimelineLite()

    timeline.to(box, 2, {
      x: 0,
      width: '35%',
      ease: Back.easeInOut // Specify an ease
    })
    timeline.to(
      box,
      0.5,
      {},
      '-=0.5' // Run the animation 0.5s early
    )
  }
}
</script>
<style scoped>
.bg-size-100 {
  background-size: 100%;
}
.custom_bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.box {
  position: absolute;
  height: 100%;
  width: 50%;
  right: 0;
  /* margin-right: -200px; */
}
.auth__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.main__logo {
  display: block;
  max-width: 100%;
  height: auto;
}
</style>
